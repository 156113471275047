import React from 'react'
import { Header } from '../Components/Header'
import { Error404 } from '../Components/Error404'

const ErrorPage = () => {
  return (
    <>
        <Header />
        <Error404 />
    </>
  )
}

export  {ErrorPage}
import React from "react";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";
import { PrivacyPolicy } from "../Components/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export { PrivacyPolicyPage };

import React from 'react'
import ApplicationImage from "../../../Assets/applicationworks.svg";
import styles from "../ApplicationWorks/ApplicationWorks.module.css";
import home1 from "../../../Assets/home1.png";

const HomeMore = () => {
    return (
        <div className={`${styles.ApplicationWorksContainer} mt-0`}>

            <div className={styles.ApplicationWorksImgContainer}>

                <div className={styles.ApplicationWorksContentSection}>
                    <div className="fw-bold fs-4">More About Talisma Wellness</div>
                    "Our mission at Talisma Wellness App is to revolutionize workplace wellness by leveraging cutting-edge technology to empower organizations and their employees to prioritize health and well-being. Through our seamless platform, we aim to foster a culture of wellness, engagement, and productivity within organizations, enabling individuals to thrive both personally and professionally. By harnessing the power of data, technology, and community, we are committed to creating an environment where every employee feels supported, motivated, and inspired to lead their best lives. Together, we are redefining workplace wellness and paving the way for a healthier, happier, and more vibrant future."
                </div>
                <img
                    src={home1}
                    alt=""
                    className={styles.ApplicationWorksSection}
                />
            </div>
        </div>
    )
}

export default HomeMore

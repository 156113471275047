import React from "react";

import ArrowIcon from "../../Assets/arrow-right.svg";
import styles from "./TermsAndConditions.module.css";

const TermsAndConditions = () => {
  return (
    <div className={styles.TermsAndConditions__container}>
      <div>
        <div className={styles.TermsAndConditions__heading1}>
          terms and conditions
        </div>
        <div className={styles.TermsAndConditions__content}>
          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures. The domain name
            zerozilla.com (hereinafter referred to as “Website”) is owned by
            Zerozilla Infotech Private limited l its office at T1, 3rd Floor,
            Swastik Manandi Arcade Opp.Sheshadripuram police station,
            Sheshadripuram, Bengaluru, Karnataka 560020, India Your use of the
            Website, services and tools are governed by the following terms and
            conditions (“Terms of Use”) as applicable to the Website including
            the applicable policies which are incorporated herein by way of
            reference. If You transact on the Website, You shall be subject to
            the policies that are applicable to the Website for such
            transaction. By mere use of the Website, You shall be contracting
            with Zerozilla Technologies and these terms and conditions including
            the policies constitute your binding obligations, with Zerozilla
            Technologies.
          </p>

          <p>
            Please read these Terms & Conditions carefully before using this
            websites. For the purpose of these Terms of Use, you the end user is
            referred to as “you”, “your” or “user” shall mean any natural or
            legal person who has agreed to become a buyer on the Website by
            providing Registration Data while registering on the Website as
            Registered User using the computer systems. Zerozilla.com allows the
            User to surf the Website or making purchases without registering on
            the Website. VitalThings or Zerozilla.com is referred to as
            “Zerozilla” or “Company” “we,” “us,”, “our” or Company.
          </p>
          <p>
            When You use any of the services provided by Us through the Website,
            including but not limited to, (e.g. blogs, Zerozilla.com Reviews),
            You will be subject to the rules, guidelines, policies, terms, and
            conditions applicable to such service, and they shall be deemed to
            be incorporated into this Terms of Use and shall be considered as
            part and parcel of this Terms of Use. We reserve the right, at Our
            sole discretion, to change, modify, add or remove portions of these
            Terms of Use, at any time without any prior written notice to You.
            It is Your responsibility to review these Terms of Use periodically
            for updates / changes. Your continued use of the Website following
            the posting of changes will mean that You accept and agree to the
            revisions. As long as You comply with these Terms of Use, We grant
            You a personal, non-exclusive, non- transferable, limited privilege
            to enter and use the Website.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.TermsAndConditionsy__heading}>
          ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
          AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO
          PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
        </div>
        <p className={styles.TermsAndConditions__content}>
          By impliedly or expressly accepting these Terms of Use, You also
          accept and agree to be bound by Zerozilla.com Policies ((including but
          not limited to Privacy Policy available on /s/privacy policy) as
          amended from time to time.
        </p>
      </div>
      <div>
        <div className={styles.TermsAndConditions__heading}>Modified Terms</div>
        <p className={styles.TermsAndConditions__content}>
          The Company reserves the right at all times to discontinue or modify
          any of these Terms and Conditions and/or our Privacy Policy as we deem
          necessary or desirable without prior notification to you. Such changes
          may include, among other things, the adding of certain fees or
          confidentiality. We do not endorse in anyway any advertisers/ contents
          of advertisers on its web pages or other communication.
        </p>
        <p className={styles.TermsAndConditions__content}>
          We will not be responsible for any damage suffered by users from use
          of the services on this site. This without limitation includes loss of
          revenue/data resulting from delays, non-deliveries, missed deliveries.
          This disclaimer of liability also applies to any damages or injury
          caused by any failure of performance, error, omission, interruption,
          deletion, defect, delay in operation or transmission, computer virus,
          communication line failure, theft or destruction or unauthorized
          access to, alteration of, or use of record, whether for breach of
          contract, tortuous behavior, negligence, or under any other cause of
          action. User agrees and acknowledges that use shall be solely
          responsible for user’s conduct and that the Company reserves the right
          to terminate your rights to use the service immediately,
          notwithstanding penal provisions under the Indian cyber laws or any
          other allied laws enacted by the government of India or any other
          statutory, legislative or regulatory authority authorized in this
          regard from time to time. In no event shall the Company, its
          affiliates, employees, agents, consultants, contracted companies be
          liable for any direct, indirect, punitive, incidental, special or
          consequential damages or for any damages whatsoever including, without
          limitation, damages for loss of use, data or profits, arising out of
          or in any way connected with the use or performance of the
          Zerozilla.com sites/services for interrupted communications, delay,
          lost data or lost profits arising out of or in connection with this
          agreement. We therefore neither endorses nor offers any judgment or
          warranty and accepts no responsibility or liability for the
          authenticity/availability of any of the goods/services/or for any
          damage, loss or harm, direct or consequential or any violation of
          local or international laws that may be incurred by your visit and/or
          transaction/s on these sites.
        </p>
        <p className={styles.TermsAndConditions__content}>
          We shall not be liable for any delay / non-delivery of purchased
          goods, due to flood, fire, wars, acts of God or any cause that is
          beyond the control of Zerozilla.com.
        </p>
      </div>
      <div>
        <div className={styles.TermsAndConditions__heading}>
          Contents Posted on Site
        </div>
        <p>
          Except as expressly provided in these Terms of Use, no part of the
          Website and no Content may be copied, reproduced, republished,
          uploaded, posted, publicly displayed, encoded, translated, transmitted
          or distributed in any way (including “mirroring”) to any other
          computer, server, Website or other medium for publication or
          distribution or for any commercial enterprise, without Zerozilla.com’s
          express prior written consent.
        </p>
        <p>
          You may use information on the products and services purposely made
          available on the Website for downloading, provided that You (1) do not
          remove any proprietary notice language in all copies of such
          documents, (2) use such information only for your personal,
          non-commercial informational purpose and do not copy or post such
          information on any networked computer or broadcast it in any media,
          (3) make no modifications to any such information, and (4) do not make
          any additional representations or warranties relating to such
          documents.
        </p>
      </div>
      <div>
        <div className={styles.TermsAndConditions__heading}>
          Contact Information
        </div>
        <p>
          If you have questions or concerns regarding these Terms & Conditions,
          please contact our support at info@zerozilla.com
        </p>
      </div>
    </div>
  );
};

export { TermsAndConditions };

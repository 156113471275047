import React, { useRef, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import tabs1 from "../../../Assets/tabs (1).png";
import tabs2 from "../../../Assets/tabs (2).png";
import tabs3 from "../../../Assets/tabs (3).png";
import tabs4 from "../../../Assets/tabs (4).png";
import tabs5 from "../../../Assets/tabs (5).png";
import tabs6 from "../../../Assets/tabs (6).png";
import tabs7 from "../../../Assets/tabs (7).png";
import tabs8 from "../../../Assets/tabs (8).png";
import './style.css';
import { useLocation } from 'react-router-dom';

const HomeTabs = () => {
    const solutionsRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#challenges" && solutionsRef.current) {
            solutionsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.hash]);

    return (
        <Container fluid style={{ backgroundColor: '#FFECEE' }} id='challenges' ref={solutionsRef}>
            <Row>
                <Col className='my-4'>
                    <h3 className='d-flex justify-content-center'>How does Zilla Effectively Manage & Monitor your Field Staff?</h3>
                    <p className='d-flex justify-content-center'>
                        Our software suite offers location tracking, automated attendance, and task management for effective field force management.
                    </p>
                </Col>
            </Row>
            <Container>
                <Row>
                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="Events"
                            id="justify-tab-example"
                            className="mb-3 custom-tabs"
                            justify
                        >
                            <Tab eventKey="Events" title="Events" className='inside_tab '>
                                <div className="event_section  my-5 py-5 px-5">
                                    <div>
                                        <img src={tabs1} alt="" className='' />
                                    </div>
                                    <div>
                                        <img src={tabs2} alt="" />
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="Challenges" title="Challenges" className='inside_tab '>
                                <div className="event_section  my-5 py-5 px-5">
                                    <div>
                                        <img src={tabs3} alt="" className='' />
                                    </div>
                                    <div>
                                        <img src={tabs4} alt="" />
                                    </div>
                                    <div>
                                        <img src={tabs5} alt="" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Challenge" title="Challenge" className='inside_tab '>
                                <div className="event_section  my-5 py-5 px-5">
                                    <div>
                                        <img src={tabs6} alt="" className='' />
                                    </div>
                                    <div>
                                        <img src={tabs7} alt="" />
                                    </div>
                                    <div>
                                        <img src={tabs8} alt="" />
                                    </div>
                                </div>
                            </Tab>

                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Container>


    )
}

export default HomeTabs

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './HeroSection.module.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sl2 from "../../../Assets/sl2.png";
import sl3 from "../../../Assets/sl3.png";
import sl4 from "../../../Assets/sl4.png";
import sl5 from "../../../Assets/sl5.png";

const HeroNewSection = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Container fluid className={` ${styles.HeroSectionContainer}  `}>
            <Container>
                <Row >
                    <Col md={6}>
                        <div className={` ${styles.HeroSectionLeftHeading} mt-5 mb-3`}>Welcome to Talisma Wellness App </div>
                        <div className={` ${styles.HeroSectionLeftText} mb-3 `}> At Talisma Wellness App, we're dedicated to enhancing workplace wellness by harnessing the power of technology to personalize and optimize your wellness journey.
                            Our app is designed exclusively for organizations like yours, offering a seamless platform to promote employee well-being, engagement, and camaraderie.
                        </div>
                        <div className={styles.HeroSectionleftButtonContainer}>
                            <button className={styles.HeroSectionleftButton1}>Get Started</button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="slider-container my-4" >
                            <Slider {...settings}>
                                <div className='d-flex justify-content-center'  >
                                    <img src={sl2} alt="Slide" className={styles.sliderImage} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <img src={sl3} alt="Slide" className={styles.sliderImage} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <img src={sl4} alt="Slide" className={styles.sliderImage} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <img src={sl5} alt="Slide" className={styles.sliderImage} />
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default HeroNewSection

const PricingCardData = [
  {
    id: 1,
    title: "Pay based on no.of employees you have",
    price: "500",
    priceText:"/USERS",
    popular: false,
    features: [
      {
        id: 1,
        text: "Features",
      },
      {
        id: 2,
        text: "Features",
      },
      {
        id: 3,
        text: "Features",
      },
      {
        id: 4,
        text: "Features",
      },
      {
        id: 5,
        text: "Features",
      },
    ],
  },
  {
    id: 2,
    title: "Use our app by paying subscription",
    price: "12,999",
    priceText:"/YEARLY",
    popular: true,
    features: [
      {
        id: 1,
        text: "Features",
      },
      {
        id: 2,
        text: "Features",
      },
      {
        id: 3,
        text: "Features",
      },
      {
        id: 4,
        text: "Features",
      },
      {
        id: 5,
        text: "Features",
      },
    ],
  },

  {
    id: 3,
    title: "Customize and use as your own product",
    price: "35,999",
    priceText:"",
    popular: false,
    features: [
      {
        id: 1,
        text: "Features",
      },
      {
        id: 2,
        text: "Features",
      },
      {
        id: 3,
        text: "Features",
      },
      {
        id: 4,
        text: "Features",
      },
      {
        id: 5,
        text: "Features",
      },
    ],
  },
];

export { PricingCardData };

const SalesAndImpactSectionData = [
  {
    id: "1",
    text: "Participation in Wellness Challenges",
  },
  {
    id: "2",
    text: "Joining Wellness Clubs",
  },
  {
    id: "3",
    text: "Tracking Personal Wellness Data",
  },
  {
    id: "4",
    text: "Redeeming Rewards and Purchasing Products",
  },
  {
    id: "5",
    text: "Engagement and Community Building",
  },

];


export { SalesAndImpactSectionData }
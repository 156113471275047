const Section4Data = [

    // Home Page
  [
    {
      img: "",
      title: "Reports and analytics",
      para: "Gives the number of tasks completed per location, average response time by personnel, and cost analysis. ",
      link: "",
    },
    {
      img: "",
      title: "Location tracking",
      para: "Displays the real-time location of the field force personnel, along with their route history and current status. ",
      link: "",
    },
    {
      img: "",
      title: "Performance metrics",
      para: "Displays the performance metrics of the field force personnel, such as the average response time. ",
      link: "",
    },
    {
      img: "",
      title: "Field force overview",
      para: " Gives a quick summary of the total number of field force personnel, active personnel, and their locations.",
      link: "",
    },
    {
      img: "",
      title: "Field force directory",
      para: "Gives a searchable and filterable list of all field force personnel, along with their basic details such as name, job title, location, and contact information. ",
      link: "",
    },
    {
      img: "",
      title: "Task management",
      para: "Shows the tasks assigned to the field force personnel, along with their status, priority, and deadlines.",
      link: "",
    },
  ],
//   Order Page 
  [
    {
      img: "",
      title: "User interface",
      para: " Friendly user interface which doesn’t demand prior training",
      link: "",
    },
    {
      img: "",
      title: "Order booking",
      para: "Enables you to book and cancel orders with just a click",
      link: "",
    },
    {
      img: "",
      title: "Image upload feature",
      para: "Provides the option to upload photos of products",
      link: "",
    },
    {
      img: "",
      title: "Reduced errors",
      para: "Helps streamline orders thereby reducing chances of errors",
      link: "",
    },
    {
      img: "",
      title: "Ease of managing",
      para: " Removes gaps in the order management process",
      link: "",
    },
    {
      img: "",
      title: "Improved customer satisfaction",
      para: " Customer can book orders at his comfort. ",
      link: "",
    },
  ],
//   Expense Page
  [
    {
      img: "",
      title: "Easy submission",
      para: " Enables easy entry of expense detail along with attached documents.",
      link: "",
    },
    {
      img: "",
      title: "Download expense sheet",
      para: "Option to download all attached evidence for processing.",
      link: "",
    },
    {
      img: "",
      title: "Expense tracking",
      para: "Tracks expenses of employees and field staff in real time. ",
      link: "",
    },
    {
      img: "",
      title: "Expense form creation",
      para: " Allows the creation of expense reports for each employee and project. ",
      link: "",
    },
    {
      img: "",
      title: "Detailed reports",
      para: "Provides detailed reports on expenses generated for each employee. ",
      link: "",
    },
    {
      img: "",
      title: "Streamlined expense process",
      para: "Helps streamline your expense management process with improved accuracy. ",
      link: "",
    },
  ],
//  Task  Management
  [
    {
      img: "",
      title: "Task assignment",
      para: "Real-time task assignment and notification to field executives. ",
      link: "",
    },
    {
      img: "",
      title: "Task scheduling",
      para: "Stay aware of work schedules and task timings from a single platform. ",
      link: "",
    },
    {
      img: "",
      title: "Task monitoring",
      para: "Get real-time task completion status at the click of a button. ",
      link: "",
    },
    {
      img: "",
      title: "Checking for delays",
      para: "Keep track of the task delays and inconveniences in task completion",
      link: "",
    },
    {
      img: "",
      title: "Simplified management",
      para: "Enhanced management of field staff and their task completion. ",
      link: "",
    },
    {
      img: "",
      title: "Better location awareness",
      para: "Generates location information of field staff with absolute accuracy.",
      link: "",
    },
  ],
//  Attendance  Management
  [
    {
      img: "",
      title: "Accurate attendance tracking",
      para: "Helps you accurately track the attendance of field employees using GPS and other location-based technologies.  ",
      link: "",
    },
    {
      img: "",
      title: "Real-time monitoring",
      para: "Provides real-time monitoring of field employees' attendance, enabling managers to take corrective action as required. ",
      link: "",
    },
    {
      img: "",
      title: "Increased productivity",
      para: "Allows managers to identify attendance patterns and trends and optimize field operations.",
      link: "",
    },
    {
      img: "",
      title: "Compliance",
      para: "By accurately tracking attendance, businesses can ensure compliance with labour laws and regulations.  ",
      link: "",
    },
    {
      img: "",
      title: "Cost savings",
      para: "Helps businesses save costs by preventing time theft and reducing administrative tasks. ",
      link: "",
    },
    {
      img: "",
      title: "Improved decision-making",
      para: "Provides insights into attendance patterns and trends, simplifying workforce management.",
      link: "",
    },
  ],
//   Location Tracking
[
    {
      img: "",
      title: "Task optimization",
      para: "Helps optimize routes, reduce travel time, and minimize fuel costs.",
      link: "",
    },
    {
      img: "",
      title: "Continuous monitoring",
      para: " Enables monitoring of the location of vehicles and assets. ",
      link: "",
    },
    {
      img: "",
      title: "Accuracy",
      para: "Provides accurate and real-time location information. ",
      link: "",
    },
    {
      img: "",
      title: "Decision-making",
      para: "Encourages informed decision-making by managers.  ",
      link: "",
    },
    {
      img: "",
      title: "Safety",
      para: "Enhances the safety of employees by giving updated location details.",
      link: "",
    },
    {
      img: "",
      title: "Competitiveness",
      para: "Helps you stay competitive and achieve goals more effectively.",
      link: "",
    },
  ],
//   Emplyee Dashboard
[
    {
      img: "",
      title: "Increased productivity",
      para: "Managers can track employee attendance and take appropriate action to avoid delivery delays",
      link: "",
    },
    {
      img: "",
      title: "Efficient attendance tracking",
      para: "Easily know the number of days your employees have worked, their punctuality, and any absences.",
      link: "",
    },
    {
      img: "",
      title: "Improved accuracy",
      para: "Geo awareness helps in understanding the precise location and conveyance time taken by field staff",
      link: "",
    },
    {
      img: "",
      title: "Streamlined workflow processes",
      para: "Helps managers schedule tasks according to the attendance summary. ",
      link: "",
    },
    {
      img: "",
      title: "Easy salary processing",
      para: "Having an updated attendance system can help the HR team in salary processing",
      link: "",
    },
    {
      img: "",
      title: "Leave processing",
      para: " Employees can submit and track their leave applications using this tool.",
      link: "",
    },
  ],
  //    Dashboard and rEports
  [
    {
      img: "",
      title: "Reports and analytics",
      para: "Gives the number of tasks completed per location, average response time by personnel, and cost analysis. ",
      link: "",
    },
    {
      img: "",
      title: "Location tracking",
      para: "Displays the real-time location of the field force personnel, along with their route history and current status. ",
      link: "",
    },
    {
      img: "",
      title: "Performance metrics",
      para: "Displays the performance metrics of the field force personnel, such as the average response time. ",
      link: "",
    },
    {
      img: "",
      title: "Field force overview",
      para: " Gives a quick summary of the total number of field force personnel, active personnel, and their locations.",
      link: "",
    },
    {
      img: "",
      title: "Field force directory",
      para: "Gives a searchable and filterable list of all field force personnel, along with their basic details such as name, job title, location, and contact information. ",
      link: "",
    },
    {
      img: "",
      title: "Task management",
      para: "Shows the tasks assigned to the field force personnel, along with their status, priority, and deadlines.",
      link: "",
    },
  ],
];

export { Section4Data };

import React, { useRef, useEffect } from 'react'
import PlatformImg from "../../../Assets/AppPlatform.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import styles from "../AppPlatform/AppPlatform.module.css";
import { AboutData } from './aboutData';
import { Col, Container, Row } from 'react-bootstrap';
import home2 from "../../../Assets/home2.png";
import { useLocation } from 'react-router-dom';

const HomeAbout = () => {

    const solutionsRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#about" && solutionsRef.current) {
            solutionsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.hash]);
    return (

        <Container id='about' ref={solutionsRef}>
            <Row>
                <Col md={4}>
                    <img src={home2} alt="" className={` ${styles.AppPlatformSection}  mt-lg-5 pt-lg-5  w-100`} />
                </Col>
                <Col md={8}>
                    <div className={styles.AppPlatformContainer}>

                        <div className={styles.AppPlatformImgContainer}>
                            <div className={styles.AppPlatformContentSection}>
                                {/* <div className={styles.AppPlatformHeading}>Get zilla app</div> */}
                                <div className={` ${styles.AppPlatformContent}  `}>About Talisma</div>
                                <ul>
                                    {AboutData.map((data) => (
                                        <li key={data.id} className="my-3">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={ArrowRight}
                                                    height="20px"
                                                    width="20px"
                                                    className="me-3"
                                                />
                                                <div className={styles.AppPlatformContentSectionPoints}>
                                                    {data.text}
                                                </div>{" "}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>

                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default HomeAbout

const HeroSectionData = [
  // Chat
  [
    {
      id: 1,
      name: "Chat Feature",
      title: "Communicate in real-time with your field force ",
      description:
        "Improve collaboration and streamline communication between team members. ",
      image: require("../Assets/Chat/ChatHeroImage.svg"),
    },
  ],
  // Order
  [
    {
      id: 2,
      name: "Order",
      title: "Book your orders while on the move!",
      description:
        "Streamline your order management processes without hindrance.",
      image: require("../Assets/Order/OrderHeroImage.svg"),
    },
  ],
  // Expense Management
  [
    {
      id: 3,
      name: "Expense management",
      title: "Don’t let expense management eat into your time and effort!",
      description:
        "Automated expense tracking and approval, with customizable expense categories and limits.",
      image: require("../Assets/expenseManagement/expenseManagement__heroImage.svg"),
    },
  ],
  // Task Management
  [
    {
      id: 3,
      name: "Task management",
      title: "Efficiently manage and track tasks with our geo-verified platform. ",
      description:
        "Get a comprehensive overview of all your geo-tagged tasks with GPS accuracy. ",
      image: require("../Assets/TaskManagement/TaskManagement__heroImage.svg"),
    },
  ],
  // Attendance
  [
    {
      id: 4,
      name: "Attendance",
      title: "Effortlessly track field employee attendance with our app ",
      description:
        "Streamline attendance tracking today and optimize your field operations .",
      image: require("../Assets/Attendance/Attendance__heroImage.svg"),
    },
  ],
  // LOcation Tracking
  [
    {
      id: 4,
      name: "location tracking",
      title: "Track your field staff in real-time ",
      description:
        "Monitor your field force with enhanced location awareness.",
      image: require("../Assets/LocationTracking/LocationTracking__heroImage.svg"),
    },
  ],
  // Employee Dashboard
  [
    {
      id: 5,
      name: "Employee Management",
      title: "Improve employee productivity by optimising operations",
      description:
        "Manage and save time for your field force and other employees by using the power of location intelligence. .",
      image: require("../Assets/EmployeeDashboard/EmployeeDashboard__heroImage.svg"),
    },
  ],
    //  Dashboard and Reports
    [
      {
        id: 5,
        name: "dashboards and reports",
        title: "Get Actionable Insights and Reports with Zilla Dashboard",
        description:
          "Personalized dashboard for employees with access to personal data and performance analysis.",
        image: require("../Assets/DashboardAndReports/Reports__heroImage.svg"),
      },
    ],
];

export { HeroSectionData };

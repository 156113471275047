import React from "react";
import { Header } from "../Components/Header";
import { ContactUsForm } from "../Components/ContactusForm";
import { AppPlatform } from "../Components/Home/AppPlatform";
import { Footer } from "../Components/Footer";

const ContactUs = () => {
  return (
    <>
      {/* <Header /> */}
      <ContactUsForm type={"page"}/>
      {/* <AppPlatform />
      <Footer /> */}
    </>
  );
};

export  {ContactUs};

import React from "react";
import PlayIcon from "../../Assets/Play button.svg";
import styles from "./HeroSection.module.css";
import HeroImage from "../../Assets/Chat/ChatHeroImage.svg";
import { HeroSectionData } from "../../ReusableData/HeroSectionData";

const HeroSection = ({HeroSection,nestedHeroSection}) => {
    console.log("hero",HeroSection,HeroSectionData[HeroSection]?.[nestedHeroSection]?.image?.default);
  return (
    <>
      <div className={styles.ChatHeroSectionContainer}>
        <div className={styles.ChatHeroSectionLeft}>
          <div className={styles.ChatHeroSectionLeftContent}>
            <div className={styles.ChatHeroImageTopText}>{HeroSectionData[HeroSection]?.[nestedHeroSection]?.name}</div>
            <div className={styles.ChatHeroSectionLeftHeading}>
             {HeroSectionData[HeroSection]?.[nestedHeroSection]?.title}
            </div>
            <div className={styles.ChatHeroSectionLeftText}>
             {HeroSectionData[HeroSection]?.[nestedHeroSection]?.description}
            </div>
          </div>
          <div className={styles.ChatHeroSectionleftButtonContainer}>
            <button className={styles.ChatHeroSectionleftButton1}>
              Request Demo
            </button>
          </div>
        </div>
        <div className={`${styles.ChatHeroSectionRight} `}>
          <img
            src={HeroSectionData[HeroSection]?.[nestedHeroSection]?.image?.default}
            alt="Hero Image"
            className={`${styles.ChatHeroSectionRightImg} mt-3`}
          />
        </div>
      </div>
    </>
  );
};

export { HeroSection };

import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import IndustriesIcon from "../../Assets/arrow-right.svg";
import styles from "./PricingCard.module.css";
import { PricingCardData } from "./PricingCardData";

const PricingCard = () => {
  return (
    <>
      <div class={styles.wrapper}>
        {PricingCardData.map((data) => (
          <div class={`${styles.table} ${styles.basic}`}>
            {data.popular === true && (
              <div class={styles.ribbon}>
                <span>POPULAR</span>
              </div>
            )}
            <div className={styles.cardHeading}>{data.title}</div>
            <div className={styles.priceContainer}>
              <div>₹</div>
              <div className={styles.priceNumber}>{data.price}</div>
              <div className={styles.priceText}>{data.priceText}</div>
            </div>
            <ul class={styles.features}>
              {data.features.map((value) => (
                <li>
                  <img src={IndustriesIcon} alt="icon" className="me-2"/>
                  <span class={styles.listName}>{value.text}</span>
                </li>
              ))}
            </ul>
            <div class={styles.btn}>
              <button>Purchase</button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { PricingCard };

import React from "react";
import { Header } from "../Components/Header";
import { HeroSection } from "../Components/HeroSection/HeroSection";
import { CapabilitiesSection } from "../Components/Chat/CapabilitiesSection";
import { TabsSection } from "../Components/Chat/TabsSection";
import { ContactUsSection } from "../Components/Home/ContactUsSection";
import { AppPlatform } from "../Components/Home/AppPlatform";
import { FaqSection } from "../Components/Home/FAQ";
import { Footer } from "../Components/Footer";

const Chat = () => {
  return (
    <>
      <Header />
      <HeroSection HeroSection={0} nestedHeroSection={0}/>
      <CapabilitiesSection data={1}/>
      <TabsSection />
      <ContactUsSection />
      <AppPlatform />
      <FaqSection />
      <Footer />
    </>
  );
};

export { Chat };

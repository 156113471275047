import React, { useState } from "react";
import styles from "./TabsSection.module.css";
import { Tab, Tabs } from "../../../ReusableUI/Tabs";
import { SalesAndImpactSection } from "../../Home/SalesAndImpactSection";
import { CommunicateSection } from "../CommunicateSection";

const TabsSection = () => {
  const [active, setActive] = useState(0);
  const handleChange = (newActive) => setActive(newActive);
  return (
    <>
      <div className={styles.TabsSectionContainer}>
        <Tabs active={active} onChange={handleChange}>
          <Tab title="Communicate">
            <CommunicateSection CommunicationData={0} nestedCommunicationData={0} />{" "}
          </Tab>
          <Tab title="Collab">
            {" "}
            <CommunicateSection CommunicationData={1} nestedCommunicationData={0}/>
          </Tab>
          <Tab title="Co-Ordinate">
            {" "}
            <CommunicateSection CommunicationData={2} nestedCommunicationData={0}/>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export { TabsSection };

import React from 'react'
import styles from './ContactUsSection.module.css'

const ContactUsSection = () => {
  return (
    <div className={styles.ContactUsContainer}>
        <div className={styles.ContactUsContent}>Reach us for any inquiries or support</div>
        <button className={styles.ContactUsButton}>Contact Us</button>
    </div>
  )
}

export  {ContactUsSection}
import React from "react";
import styles from "./Section2.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Section2Data } from "../../ReusableData/Section2Data";

const Section2 = ({ section2__data, nestedsection2__data }) => {
 
  return (
    <>
      <div className={styles.Section2Container}>
        <Container>
          <Row className={styles.Section2Row}>
            <Col xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <div className={styles.Section2Left}>
                <img
                  src={
                    Section2Data[section2__data]?.[nestedsection2__data]?.image
                      ?.default
                  }
                  className={styles.Section2LeftImg}
                  alt="image" 
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className={styles.Section2Right}>
                <div className={styles.Section2Heading}>
                  Our online order booking system simplifies the process for
                  you. With just a few clicks, you can book your orders and
                  manage your inventory.
                </div>
                <ul className={styles.Section2Content}>
                  {Section2Data[section2__data]?.[nestedsection2__data]?.text.map(
                    (value,index) => (
                      <li key={index} className="my-2">{value?.data}</li>
                    )
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export { Section2 };

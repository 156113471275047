import React from 'react'
import { Header } from '../Components/Header'
import { PricingCard } from '../Components/PricingCard'
import { ContactUs } from './ContactUs'
import { AppPlatform } from '../Components/Home/AppPlatform'
import { FaqSection } from '../Components/Home/FAQ'
import { Footer } from '../Components/Footer'
import { ContactUsSection } from '../Components/Home/ContactUsSection'

const Pricing = () => {
  return (
    <>
    <Header />
    <PricingCard />
    <ContactUsSection />
    <AppPlatform />
    <FaqSection />
    <Footer />
    </>
  )
}

export  {Pricing}
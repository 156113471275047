const Section2Data = [
  // Order
  [
    {
      image: require("../Assets/Order/Order__Section2.svg"),
      title:
        "We are committed to providing our customers with the best tools to manage their orders effectively.",
      text: [
        {
          id: 1,
          data: "With Fieldmate, you can manage orders with ease and ensure that your business runs smoothly. ",
        },
        {
          id: 2,
          data: "Our order management system is designed to be user-friendly and customizable to meet the specific needs of your business. You can easily create and track orders, and monitor their progress in real-time. ",
        },
        {
          id: 3,
          data: "You can view all your orders in one place, with detailed information about each order, such as customer information, product details, order status, and delivery information. ",
        },
        {
          id: 4,
          data: "With our software, you can also easily manage returns, refunds, and cancellations. You can track the status of each return, issue refunds quickly, and make adjustments to your inventory in real time. ",
        },
        {
          id: 5,
          data: "Fieldmate also includes analytics and reporting features, allowing you to gain insights into your sales performance, identify trends, and make data-driven decisions. ",
        },
      ],
    },
  ],
  // Expense
  [
    {
      image: require("../Assets/Order/Order__Section2.svg"),
      title:
        "Expense management is a critical aspect of any business, as it involves tracking, controlling, and optimizing the expenses incurred by a company.",
      text: [
        {
          id: 1,
          data: "Managing expenses can be a time-consuming and frustrating task for any business. Fieldmate offers a comprehensive expense management solution that simplifies the process and allows you to keep track of your expenses easily.",
        },
        {
          id: 2,
          data: "Track travel and other expenses by creating expense reports. Your field staff can also set up approval workflows to ensure that expenses are approved by the appropriate manager before they are reimbursed.",
        },
        {
          id: 3,
          data: "You may monitor expenses in real-time, giving you a clear understanding of your expenses and allowing you to identify areas where you can cut costs. This feature also allows you to generate detailed expense reports, helping you to make informed decisions about your business.",
        },
      ],
    },
  ],
  // Task
  [
    {
      image: require("../Assets/Order/Order__Section2.svg"),
      title:
        "Empower your field executives by helping them manage their tasks with ease! ",
      text: [
        {
          id: 1,
          data: "Here’s a collaborative space for your field staff to align tasks and keep a track of them. This helps to ensure that everyone is on the same page and that tasks are completed as efficiently as possible.",
        },
        {
          id: 2,
          data: "The task Management feature of Fieldmate provides a comprehensive solution for managing tasks and ensuring that your team is working efficiently. With our platform, you can easily assign tasks, track progress, prioritize tasks, and collaborate with team members to help improve your team's performance. ",
        },
        {
          id: 3,
          data: "Tasks can be prioritized based on their level of importance. This allows you to manage urgent tasks first, ensuring that your team is working on the most critical tasks.",
        },
        {
          id: 4,
          data: "You can track the status of each task in real time. This helps you to ensure that each task is completed on time and to a high standard.  ",
        },
        {
          id: 5,
          data: "You can easily update the status of a task from the dashboard, making it easy to keep track of progress. If a task is delayed, you can quickly identify it and take corrective action. ",
        },
      ],
    },
  ],
  // Attendance
  [
    {
      image: require("../Assets/Attendance/Attendance__Section2.svg"),
      title:
        "Get accurate attendance records to prevent time theft and improve efficiency ",
      text: [
        {
          id: 1,
          data: "Attendance management is a critical feature in field force management apps that enables businesses to track the attendance of their field employees.",
        },
        {
          id: 2,
          data: "Know the number of hours your employees have worked by paying attention to the punch-in and out timings.  ",
        },
        {
          id: 3,
          data: "Fieldmate’s attendance management feature tracks the attendance of employees in real time. This helps managers to monitor attendance and take corrective action in real time. ",
        },
        {
          id: 4,
          data: "Fieldmate helps in tracking productivity efficiently for both field staff and people at the office. It enables employees to mark their attendance from their mobile devices, making attendance tracking more convenient and efficient. ",
        },
        {
          id: 5,
          data: "Employees can record their attendance on the app thereby making it easy to know which employee is on leave. ",
        },
      ],
    },
  ],
  // LOcation Tracking
  [
    {
      image: require("../Assets/LocationTracking/LocationTracking__Section2.svg"),
      title:
        " Tired of inefficient routes, long travel times, and high fuel costs? Get enhanced field force management with a single tool!",
      text: [
        {
          id: 1,
          data: " Many businesses struggle with the task of managing and monitoring their on-field workforce effectively. Fieldmate is the ultimate solution that can help you streamline your daily business operations by tracking your field force in real-time. ",
        },
        {
          id: 2,
          data: "It uses GPS technology to track the real-time location of your field force employees enabling you to optimize their routes, improve productivity, and enhance customer service.  ",
        },
        {
          id: 3,
          data: "Fieldmate encompasses features like live location tracking, route optimization, geofencing, and time and attendance tracking. It uses real-time location data and traffic patterns to suggest the most efficient routes for your employees and vehicles.  ",
        },
      ],
    },
  ],
  // Employee Dashboard
  [
    {
      image: require("../Assets/EmployeeDashboard/EmployeeDashboard__Section2.svg"),
      title:
        "Geo-aware Auto Attendance Management System that tracks and updates the employee's attendance with utmost precision. ",
      text: [
        {
          id: 1,
          data: "Fieldmate helps the employees to automatically mark their attendance based on workday start and end, remotely or from the office location, thereby helping them schedule their work.  ",
        },
        {
          id: 2,
          data: "An efficient attendance management system helps managers plan and assign work more efficiently, ensuring that all tasks are completed on time. ",
        },
        {
          id: 3,
          data: "The software also helps in leave management by allowing employees to request time off and for managers to approve or reject their requests. This way the tool keeps track of employee leave balances and provides alerts when an employee is close to exceeding their allocated leave. ",
        },{
          id: 4,
          data: "Fieldmate functions as an in-built communication tool allowing the managers and employees to communicate with each other quickly and easily. This enables the managers to stay informed of any issues that may arise and help employees get the support they need. ",
        },
      ],
    },
  ],
   //  Dashboard and Reports
   [
    {
      image: require("../Assets/DashboardAndReports/Reports__Section2.svg"),
      title:
        "Dashboards and reports are powerful features in Salesforce that allow users to analyze data and gain insights into their business operations.",
      text: [
        {
          id: 1,
          data: "A dashboard is a visual representation of data that provides a quick and easy way to view key metrics and trends. It can be customized to display a variety of data such as charts, tables, and graphs. Dashboards can be shared with other users, and can also be set up to refresh automatically to show the most up-to-date information.",
        },
        {
          id: 2,
          data: "Reports, on the other hand, are detailed analyses of data that can be used to answer specific business questions. Reports can be created on any object in Salesforce, and can be customized to show the data in a variety of formats such as tables, charts, or matrices. Reports can also be scheduled to run at specific times and can be exported to a variety of formats including Excel, PDF, and CSV.",
        },
        {
          id: 3,
          data: "       Salesforce also provides a wide range of features for customizing and managing dashboards and reports. Users can create custom report types, add filters and groupings, and customize the layout and appearance of their dashboards and reports.",
        }
      ],
    },
  ],
];

export { Section2Data };





const ScopeData = [
    {
        id: "1",
        text: "Administrator Dashboard",
    },
    {
        id: "2",
        text: "Employee Interface",
    },
    {
        id: "3",
        text: "Wellness Challenges  ",
    },
    {
        id: "4",
        text: "Product Redemption System ",
    },
    {
        id: "5",
        text: "Data Privacy and Security",
    },



];


export { ScopeData }
import React from "react";
import { Header } from "../Components/Header";
import { HeroSection } from "../Components/Home/HeroSection";
import { ManageAndMonitorSection } from "../Components/Home/ManageAndMonitorSection";
import { IndustrySection } from "../Components/Home/IndustrySection";
import { BenefitSection } from "../Components/Home/BenefitSection";
import { SalesAndImpactSection } from "../Components/Home/SalesAndImpactSection";
import { ProductSection } from "../Components/Home/ProductSection";
import { ApplicationWorks } from "../Components/Home/ApplicationWorks";
import { AutomatedAppProcess } from "../Components/Home/AutomatedAppProcess";
import { ContactUsSection } from "../Components/Home/ContactUsSection";
import { AppPlatform } from "../Components/Home/AppPlatform";
import { FaqSection } from "../Components/Home/FAQ";
import { Footer } from "../Components/Footer";
import HomeAbout from "../Components/Home/homeAbout/HomeAbout";
import HomeMore from "../Components/Home/homeMore/HomeMore";
import HomeNewSection from "../Components/Home/homenew/HomeNewSection";
import HomeScope from "../Components/Home/Homesocpe/HomeScope";
import HomeTabs from "../Components/Home/hometabs/HomeTabs";
import HeroNewSection from "../Components/Home/HeroSection/HeroNewSection";
import { ContactUs } from "./ContactUs";

const Home = () => {
  return (
    <>
      <Header />
      {/* <HeroSection /> */}
      <HeroNewSection />
      {/* <ManageAndMonitorSection section5__data={0}/>
      <IndustrySection data={0} />
      <BenefitSection section4__data={0}/> */}
      <HomeAbout />
      <HomeMore />
      {/* <HomeNewSection /> */}
      <HomeScope />
      <SalesAndImpactSection />
      {/* <ProductSection /> */}
      <HomeTabs />
      <ContactUs />
      <ApplicationWorks />
      {/* <AutomatedAppProcess /> */}
      <ContactUsSection />
      <AppPlatform />
      <FaqSection />
      <Footer />
    </>
  );
};

export { Home };

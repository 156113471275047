import React, { useState } from 'react';

import PlusIcon from '../../Assets/PlusIcon.svg'
import MinusIcon from '../../Assets/MinusIcon.svg'
import styles from  './Accordion.module.css';

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.accordion}>
      <div className={`${styles.accordionHeader} ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
        <div className={styles.accordionTitle}>{title}</div>
        <span className={styles.arrow}><img src={isOpen ? MinusIcon : PlusIcon} alt="icon"/></span>
      </div>
      {isOpen && (
        <div className={styles.accordionContent}>
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export  {Accordion};

const AboutData = [
    {
        id: "1",
        text: "At Talisma Wellness App, we're dedicated to enhancing workplace wellness by harnessing the power of technology to personalize and optimize your wellness journey",
    },
    {
        id: "2",
        text: "With Talisma Wellness App, administrators have the tools to create dynamic challenges, establish vibrant clubs, and curate a selection of products tailored to support holistic wellness within your organization. ",
    },
    {
        id: "3",
        text: "Whether it's a step challenge, mindfulness meditation program, or nutrition club, our platform empowers administrators to foster a culture of health and vitality.  ",
    },
    {
        id: "4",
        text: "Employees are invited to participate in these challenges, tracking their progress and competing for top spots on the leaderboard. ",
    },
    {
        id: "5",
        text: "What sets us apart is our integration with Google Fit, allowing users to seamlessly sync their fitness data, including steps, distance, and activity duration, enhancing the accuracy of their wellness journey.",
    },
    {
        id: "6",
        text: "Join us in creating a workplace where well-being thrives, engagement soars, and productivity flourishes. Experience the Talisma Wellness App today and unlock a new era of workplace wellness.  ",
    },


];


export { AboutData }
import React from "react";
import styles from "./Tabs.module.css";

const Tabs = ({ active, onChange, children }) => {
  return (
    <>
      <div
       className={styles.TabsSection}
      >
        {children.map((c, index) => (
          <a
            href={"javascript: void(0)"}
            onClick={() => onChange(index)}
            className={`TabsSectionTab ${active === index ? styles.activeTab : ""} `}
          >
            {c.props.title}
          </a>
        ))}
      </div>
      <div>{children[active]}</div>
    </>
  );
};

export  {Tabs};

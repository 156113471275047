import React, { useEffect, useRef } from "react";

import faq from "../../../Assets/faq.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import { FaqSectionData } from "./FaqSectionData";

import styles from "./FaqSection.module.css";
import { Accordion } from "../../../ReusableUI/Accordion";
import { useLocation } from "react-router-dom";

const FaqSection = () => {
  const solutionsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
      if (location.hash === "#faq" && solutionsRef.current) {
          solutionsRef.current.scrollIntoView({ behavior: "smooth" });
      }
  }, [location.hash]);
  return (
    <div id="faq" className={styles.FaqContainer}>
      <div className={styles.FaqSectionContent}>
        <div className={styles.FaqSectionHeader}>FAQs</div>
        <div className={styles.FaqSectionText}>
          Answers to Your Common Questions
        </div>
      </div>
      <div className={styles.FaqImgContainer}>
        <img src={faq} alt="" className={styles.FaqSection} />
        <div className={styles.FaqContentSection}>
          {/* <ul>
          {FaqSectionData.map((data) => (
            <li key={data.id} className="my-3">
              <div className="d-flex align-items-center">
                <img src={ArrowRight} height="20px" width="20px" className="me-3" />
                <div className={styles.FaqContentSectionPoints}>
                {data.text}
                </div>{" "}
              </div>
            </li>
          ))}
          </ul> */}
          <Accordion
            title="How does the Talisma Wellness App work within our organization "
            content="The Talisma Wellness App is designed to enhance workplace wellness by providing administrators with tools to create challenges, clubs, and curated product lists. Employees can participate in these challenges, track their progress, and redeem points for wellness products. "
          />
          <Accordion
            title="What kind of challenges can we create with the Talisma Wellness App? "
            content="Administrators can create a variety of challenges such as step challenges, mindfulness programs, nutrition clubs, and more. These challenges are customizable to align with your organization's wellness goals. "
          />
          <Accordion
            title="How does the app ensure data privacy and security? "
            content="Talisma Wellness App prioritizes the privacy and security of user data. We adhere to strict security measures to safeguard all personal information collected, ensuring that user privacy is always protected. "
          />
          <Accordion
            title="Can employees sync their fitness data with the app? "
            content="Yes, the Talisma Wellness App integrates with Google Fit, allowing users to seamlessly sync their fitness data, including steps, distance, and activity duration. This integration enhances the accuracy of the wellness journey and provides valuable insights for users. "
          />
          <Accordion
            title="How are redeemable points earned and used within the app? "
            content="Employees earn redeemable points by participating in challenges and achieving top positions on the leaderboard. These points can be used to purchase wellness products curated by the organization's admin. The top three winners of each challenge receive redeemable points as rewards. "
          />
        </div>
      </div>
    </div>
  );
};

export { FaqSection };

const CommunicationSectionData = [
  [
    {
      id: "1",
      text: "Secure and encrypted communication. ",
    },
    {
      id: "2",
      text: "Allows communication with multiple team members. ",
    },
    {
      id: "3",
      text: "Helps set up notification alerts to help you stay in the loop. ",
    },
   
   
  ],
  [
    {
      id: "1",
      text: "Ensures that everyone is on the same page. ",
    },
    {
      id: "2",
      text: "Helps you quickly answer questions and resolve issues. ",
    },
    {
      id: "3",
      text: "Allows you to provide guidance, improving the productivity and efficiency of your team. ",
    },
    
   
  ],
  [
    {
      id: "1",
      text: "Aligns the activities of field staff, customers and managers perfectly. ",
    },
    {
      id: "2",
      text: "Ensures a steady line of communication between the parties involved. ",
    },
    {
      id: "3",
      text: "Helps maintain a healthy flow of information always through the chat. ",
    },
    
   
  ],
];

const CommunicationSectionImg = [
  [
    {
      id: 1,
      image: require("../../../Assets/Chat/Communicate.png"),
      title:"Communicate",
      desc:"Send and receive messages, images, and files instantly, keeping everyone informed and up-to-date on the status of tasks and projects: ",
    },
  ],
  [
    {
      id: 2,
      image: require("../../../Assets/Chat/Communicate.png"),
      title:"Collab",
      desc:"Quickly resolve issues, answer questions, and provide guidance, improving the productivity and efficiency of your team:",
    },
  ],
  [
    {
      id: 3,
      image: require("../../../Assets/Chat/Communicate.png"),
      title:"Co-Ordinate",
      desc:"Managers, field staff and customers can stay on the same page through continuous communication and collaboration:",
    },
  ],
];
export { CommunicationSectionData, CommunicationSectionImg };

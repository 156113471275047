import React from "react";

import ArrowIcon from '../../Assets/arrow-right.svg'
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.PrivacyPolicy__container}>
      <div>
        <div className={styles.PrivacyPolicy__heading1}>Privacy Policy</div>
        <div className={styles.PrivacyPolicy__content}>
          <p>
            At Zerozilla, accessible from https://zerozilla.com/, one of our
            main priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Zerozilla and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Zerozilla. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>Consent</div>
        <p className={styles.PrivacyPolicy__content}>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>Information we collect</div>
        <p className={styles.PrivacyPolicy__content}>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p className={styles.PrivacyPolicy__content}>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p className={styles.PrivacyPolicy__content}>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>How we use your information</div>
        <p>We use the information we collect in various ways, including to:</p>
        <ul>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Provide, operate, and maintain our webste</li>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Improve, personalize, and expand our website</li>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Understand and analyze how you use our website</li>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Develop new products, services, features, and functionality</li>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Send you emails</li>
          <li> <img src={ArrowIcon} alt="icon" className="me-2"/>Find and prevent fraud</li>
        </ul>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>Log Files</div>
        <p>
          Zerozilla follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
          Our Privacy Policy was created with the help of the Privacy Policy
          Generator and the Disclaimer Generator .
        </p>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>Cookies and Web Beacons</div>
        <p>
          Like any other website, Zerozilla uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </p>
        <p>
          For more general information on cookies, please read "What Are
          Cookies" .
        </p>
      </div>
      <div>
        <div className={styles.PrivacyPolicy__heading}>Third Party Privacy Policies</div>
        <p>
          Zerozilla's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>
      </div>
    </div>
  );
};

export { PrivacyPolicy };

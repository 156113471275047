const Section5Data = [
  // Home Page
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Automatic distance calculation with geofence alerts",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: " Real-time visibility by tracking attendance",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Real-time task assignment and monitoring",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Option to update bills and reimbursement. ",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Place and manage orders with ease.",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "In-built support feature to raise a query. ",
      link: "",
    },
  ],
  //   Order Page
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //   Expense management Page
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //   Task management Page
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //   Attendance Management
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //   Location Tracking
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //   Employee Dashboard
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
  //    Dashboard and Reports
  [
    {
      img: "",
      title: "Location Tracking",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Attendance",
      para: "Know attendance real time Flexible attendance options",
      link: "",
    },
    {
      img: "",
      title: "Task Management",
      para: "Geo verified tasks/meetings, photos Real time task assignment",
      link: "",
    },
    {
      img: "",
      title: "Expense Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Order Management",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
    {
      img: "",
      title: "Chat support",
      para: "Real time location tracking Automatic distance calculation",
      link: "",
    },
  ],
];

export { Section5Data };

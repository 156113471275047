import React, { useEffect, useRef, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from "react-router-dom";

import phoneIcon from "../../Assets/ContactUs/phonecall.svg";
import emailIcon from "../../Assets/ContactUs/contactemail.svg";
import locationIcon from "../../Assets/ContactUs/contactLocation.svg";
import locationImage from "../../Assets/ContactUs/Location.svg";
import styles from "./ContactUsForm.module.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const SERVICEID = "service_lssqlwf";
const TEMPLATEID = "template_wav04ns";
const PUBLICID = "8cddXBaDTVa3tkODW";

const ContactUsForm = (type) => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    enquiy_email: "",
    enquiry_contact: "",
    enquiry_message: "",
  });

  console.log(type, "Type");

  const notify = () => {
    toast.success(
      "Thank You, Got your message! Our team is on standby, and you can expect a response in 24 hours",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    emailjs.send(SERVICEID, TEMPLATEID, values, PUBLICID).then(
      (response) => {
        notify();
        setValues({
          first_name: "",
          last_name: "",
          enquiy_email: "",
          enquiry_contact: "",
          enquiry_message: "",
        });
      },
      (err) => {
        console.log(
          `Hello!, there is some network issue please check your internet connection and re-submit the request`
        );
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const solutionsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#callback" && solutionsRef.current) {
      solutionsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <>
      <div
        className={type?.type === "model" ? "" : styles.ContactUsForm__Main}
        id="callback"
        ref={solutionsRef}
      >
        <Container
          className={
            type?.type === "model" ? "" : styles.ContactUsForm__Container
          }
        >
          <Row>
            <Col
              xs={12}
              md={type?.type === "model" ? 12 : 6}
              lg={type?.type === "model" ? 12 : 6}
            >
              <div className={styles.ContactUsForm__heading1}>Contact Us</div>
              <div className={styles.ContactUsForm__heading2}>
                Get in touch with us
              </div>
              <form onSubmit={handleSubmit}>
                <Row className={styles.ContactUsForm__row}>
                  <Col
                    xs={12}
                    md={type?.type === "model" ? 12 : 6}
                    lg={type?.type === "model" ? 12 : 6}
                  >
                    <div>
                      <label className={styles.ContactUsForm__label}>
                        First Name
                      </label>
                    </div>
                    <input
                      className={styles.ContactUsForm__input}
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={type?.type === "model" ? 12 : 6}
                    lg={type?.type === "model" ? 12 : 6}
                  >
                    <div>
                      <label className={styles.ContactUsForm__label}>
                        Last Name
                      </label>
                    </div>
                    <input
                      type="text"
                      className={styles.ContactUsForm__input}
                      placeholder="Last Name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.ContactUsForm__row}>
                  <Col
                    xs={12}
                    md={type?.type === "model" ? 12 : 6}
                    lg={type?.type === "model" ? 12 : 6}
                  >
                    <div>
                      <label className={styles.ContactUsForm__label}>
                        Email
                      </label>
                    </div>
                    <input
                      type="text"
                      className={styles.ContactUsForm__input}
                      placeholder="Email Address"
                      name="enquiy_email"
                      value={values.enquiy_email}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={type?.type === "model" ? 12 : 6}
                    lg={type?.type === "model" ? 12 : 6}
                  >
                    <div>
                      <label className={styles.ContactUsForm__label}>
                        Phone Number
                      </label>
                    </div>
                    <input
                      type="text"
                      className={styles.ContactUsForm__input}
                      placeholder="Phone Number"
                      name="enquiry_contact"
                      value={values.enquiry_contact}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.ContactUsForm__row}>
                  <Col xs={12} md={12} lg={12}>
                    <div>
                      <label className={styles.ContactUsForm__label}>
                        Message
                      </label>
                    </div>
                    <input
                      type="text"
                      className={`${styles.ContactUsForm__input} ${styles.ContactUsForm__input1}`}
                      placeholder="Message"
                      name="enquiry_message"
                      value={values.enquiry_message}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <button type="submit" className={styles.ContactUsForm__button}>
                  Send Message
                </button>
              </form>
            </Col>
            {type.type === "model" ? null : (
              <Col xs={12} md={6} lg={6}>
                <div className={styles.ContactUsForm__Right}>
                  <div className={styles.ContactUsForm__headingColor}>
                    Contact Information
                  </div>
                  {/* <div className={styles.ContactUsForm__rightContent}>
                  <div>+91 8069261222</div>
                </div>{" "} */}
                  {/* <div className={styles.ContactUsForm__rightContent}>
                  <div>info@talismawellness.com</div>
                </div>{" "} */}
                  {/* <div className={styles.ContactUsForm__rightContent}>
                  <div>
                  Talisma Corporation Pvt. Ltd,Ground Floor,Phoenix - Magnificia, Vijanapura, Mahadevapura Ward, Old Madras Road, Dooravaninagar,Bangalore - 560 016, Karnataka, India.
                    </div>
                </div> */}
                  <div>
                    <iframe
                      className={styles.ContactUsForm__LocationImg}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.604895466158!2d77.66769867478102!3d12.997103964319235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11522d2d36d5%3A0x2eecf504352ae269!2sTalisma%20Corporation%20Private%20Limited!5e0!3m2!1sen!2sin!4v1712128236924!5m2!1sen!2sin"
                      width="100%"
                      height="250px"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    {/* <img src={locationImage} alt="location"  /> */}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export { ContactUsForm };

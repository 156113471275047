import PhoneIcon from '../../Assets/ContactUs/phonecall.svg'
import EmailIcon from '../../Assets/ContactUs/contactemail.svg'
import AddressIcon from '../../Assets/ContactUs/contactLocation.svg'
import AppStore from "../../Assets/appstore.svg";
import PlayStore from "../../Assets/googleplay.svg";

const FooterData = [
  {
    id: 1,
    title: "Management",
    // text1: "Location Tracker",
    // text2: "Attendance",
    // text3: "Task Management",
    // text4: "Expense Management",
    // text5: "Order",
  },
  {
    id: 2,
    title: "Tools",
    // text1: "Dashboards & Reports",
    // text2: "Employee Dashboards",

  }, {
    id: 3,
    title: "Quick Links",
    // text1: "Home",
    // text2: "Contact",

  }, {
    id: 4,
    title: "Contact Us",
    phoneIcon: "",
    text1: "+91 8069261222",
    EmailIcon: "",
    text2: "info@zerozilla.com",
    AddressIcon: "",
    text3: "No.471, 1st Floor, 80ft Road, Aman Avenue, opp. to BMTC Depot, 6th Block, Koramangala - 560095",

  },
];

const FootersData = [
  // {
  //   id: 1,
  //   title: "Mission",
  //   links: [
  //     // {
  //     //   id: 1,
  //     //   text: "Location Tracker",
  //     //   link: "/location-tracking",
  //     //   icon: ""
  //     // },
  //     // {
  //     //   id: 2,
  //     //   text: "Attendance",
  //     //   link: "/attendance",
  //     //   icon: ""
  //     // },
  //     // {
  //     //   id: 3,
  //     //   text: "Task Management",
  //     //   link: "/task-management",
  //     //   icon: ""
  //     // },
  //     // {
  //     //   id: 4,
  //     //   text: "Expense Management",
  //     //   link: "/expense-management",
  //     //   icon: ""
  //     // },
  //     // {
  //     //   id: 5,
  //     //   text: "Order",
  //     //   link: "/order",
  //     //   icon: ""
  //     // },
  //   ]
  // },
  // {
  //   id: 2,
  //   title: "Scope",
  //   links: [
  //     {
  //       id: 1,
  //       text: "Uses",
  //       link: "/dashboard-and-reports",
  //       icon: ""
  //     },
  //     {
  //       id: 2,
  //       text: "Employee Dashboards",
  //       link: "/employee-dashboard",
  //       icon: "",
  //     },

  //   ]
  // },
  {
    id: 3,
    title: "Quick Links",
    links: [
      {
        id: 1,
        text: "Home Page",
        link: "/",
        icon: "",
      },
      {
        id: 2,
        text: "Privacy policy",
        link: "https://talismawellness.com/privacy-policy/index.html",
        icon: ""
      },
      {
        id: 3,
        text: "Account deletion",
        link: "https://talismawellness.com/account-deletion/index.html",
        icon: ""
      },

    ]
  },

  {
    id: 2,
    title: "App Links",
    links: [
      {
        id: 1,
        icon: AppStore

      },
      {
        id: 2,
        icon: PlayStore

      },


    ]
  },
  {
    id: 3,
    title: "Contact Us",
    links: [
      {
        id: 1,
        text: "+91 8069261222",
        link: "",
        // icon: PhoneIcon

      },
      {
        id: 2,
        text: "info@talismawellness.com",
        link: "",
        // icon: EmailIcon

      },
      {
        id: 3,
        text: "Talisma Corporation Pvt. Ltd,Ground Floor,Phoenix - Magnificia, Vijanapura, Mahadevapura Ward, Old Madras Road, Dooravaninagar,Bangalore - 560 016, Karnataka, India.",
        link: "",
        // icon: AddressIcon

      },

    ]
  },
]

export { FooterData, FootersData };

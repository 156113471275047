import React, { useRef, useEffect } from 'react'
import PlatformImg from "../../../Assets/AppPlatform.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import styles from "../AppPlatform/AppPlatform.module.css";
import { Col, Container, Row } from 'react-bootstrap';
import { ScopeData } from './scopeData';
import home3 from "../../../Assets/home3.png";
import { useLocation } from 'react-router-dom';

const HomeScope = () => {
    const solutionsRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#scope" && solutionsRef.current) {
            solutionsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.hash]);
    return (
        <Container id='scope' ref={solutionsRef}>
            <Row>
                <Col md={5}>
                    <img src={home3} alt="" className={` ${styles.AppPlatformSection}  pt-0 mx-lg-5 w-100 `} />
                </Col>
                <Col md={7}>
                    <div className={styles.AppPlatformContainer}>

                        <div className={styles.AppPlatformImgContainer}>
                            <div className={styles.AppPlatformContentSection}>
                                {/* <div className={styles.AppPlatformHeading}>Get zilla app</div> */}
                                <div className={styles.AppPlatformContent}>Scope of Talisma Wellness</div>
                                <ul>
                                    {ScopeData.map((data) => (
                                        <li key={data.id} className="my-3">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={ArrowRight}
                                                    height="20px"
                                                    width="20px"
                                                    className="me-3"
                                                />
                                                <div className={styles.AppPlatformContentSectionPoints}>
                                                    {data.text}
                                                </div>{" "}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeScope

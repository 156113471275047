import React from "react";

import { TermsAndConditions } from "../Components/TermsAndConditions";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";


const TermsPage = () => {
  return (
    <>
      <Header />
      <TermsAndConditions />
      <Footer />
    </>
  );
};

export { TermsPage };

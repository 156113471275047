import React from "react";

import ApplicationImage from "../../../Assets/applicationworks.svg";
import styles from "./ApplicationWorks.module.css";

const ApplicationWorks = () => {
  return (
    <div className={`${styles.ApplicationWorksContainer}`}>
      <div className={styles.ApplicationWorksSectionContent}>
        <div className={styles.ApplicationWorksSectionHeader}>What we offer</div>
        <div className={styles.ApplicationWorksSectionText}>
          How does Talisma Wellness application works?
        </div>
      </div>
      <div className={styles.ApplicationWorksImgContainer}>
        <img
          src={ApplicationImage}
          alt=""
          className={styles.ApplicationWorksSection}
        />
        <div className={styles.ApplicationWorksContentSection}>
          <div className="fw-bold fs-4">Elevate Your Workplace Wellness Experience</div>
          At Talisma Wellness App, we're dedicated to enhancing workplace wellness by harnessing the power of technology to personalize and optimize your wellness journey. Our app is designed exclusively for organizations like yours, offering a seamless platform to promote employee well-being, engagement, and camaraderie.
        </div>
      </div>
    </div>
  );
};

export { ApplicationWorks };

import React from "react";
import styles from "./Section3.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import cardImg1 from "../../Assets/Order/Order__Section3.1.svg";
import cardImg2 from "../../Assets/Order/Order__Section3.2.svg";
import cardImg3 from "../../Assets/Order/Order__Section3.3.svg";
import { Section3Data } from "../../ReusableData/Section3Data";

const Section3 = ({ section3__data, nestedsection3__data }) => {
  return (
    <>
      <div className={styles.Section3TopContent}>
        <div className={styles.Section3Header}>Usp</div>
        <div className={styles.Section3Text}>
        What makes us different?
        </div>
      </div>
      <div className={styles.Section3Container}>
        <Container>
          <Row className={styles.Section3Row}>
            <Col xs={12} md={6} lg={6}>
              <div className={styles.Section3Left}>
                {Section3Data[section3__data]?.[
                  nestedsection3__data
                ]?.content.map((data) => (
                  <div className={styles.Section3LeftBox}>
                    <div>
                      <img
                        src={data.image.default}
                        alt="image"
                        className="me-3"
                      />
                    </div>
                    <div>{data.text}</div>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <div className={styles.Section3Right}>
                <img
                  src={
                    Section3Data[section3__data]?.[nestedsection3__data]?.image
                      ?.default
                  }
                  alt="image"
                  className={styles.Section3__RightImage}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export { Section3 };

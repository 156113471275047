const Section3Data = [
  // order
  [
    {
      image: require("../Assets/Order/Order__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"Helps book orders in real-time. "
        },
        {
            id:2,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Ideal solution for businesses of all sizes. "
        },
        {
            id:3,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Ensures optimized business operations. "
        },
      ]
     
    },
  ],
  // expense Management
  [
    {
      image: require("../Assets/expenseManagement/Order__Section4.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"Keep a track of who is present, absent, on half-day leave and more. "
        },
        {
            id:2,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Field staff can request leave or attendance regularisation. "
        },
        {
            id:3,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"At a glance, know the attendance status of your organization."
        },
      ]
     
    },
  ],
  // Task Management
  [
    {
      image: require("../Assets/TaskManagement/TaskManagement__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"Geo-verified task management. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Custom forms to collate task details. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Supports image upload as task completion proof. "
        },
      ]
     
    },
  ],
  // Attendance Management
  [
    {
      image: require("../Assets/TaskManagement/TaskManagement__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"An attendance system automatically track attendance"
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Generate real-time reports on employee attendance"
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Assign tasks based on employee availability and skill sets."
        },
      ]
     
    },
  ],

  // Location Tracking
  [
    {
      image: require("../Assets/LocationTracking/LocationTracking__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"Enables geofencing for designated work areas. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Helps managers monitor field staff’s movements. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Avoids fake tasks by ensuring the staff’s presence within client premises"
        },
      ]
     
    },
  ],
  // Emplyee Dashboard
  [
    {
      image: require("../Assets/EmployeeDashboard/EmployeeDashboard__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"Helps Managers approve the attendance based on the configuration. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Helps the employees verify at a glance whether their attendance is approved or not. "
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Enables the HR team to get the attendance summary and detailed report for salary processing. "
        },
      ]
     
    },
  ],
  //  Dashboard and Reports
  [
    {
      image: require("../Assets/DashboardAndReports/Reports__Section3.svg"),
      content:[
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.1.svg"),
            text:"A dashboard allow you to choose the layout and format of the data"
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.2.svg"),
            text:"Graphs, charts, and other visual aids helps easily understand your data."
        },
        {
            id:1,
            image:require("../Assets/Order/Order__Section3.3.svg"),
            text:"Dashboards should allow you to set up alerts and notifications"
        },
      ]
     
    },
  ],
];

export { Section3Data };

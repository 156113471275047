const AppPlatformSectionData = [
  {
    id: "1",
    text: "A user-friendly interface for administrators to create, manage, and monitor wellness challenges, clubs, and product listings.",
  },
  {
    id: "2",
    text: "Tools to customize challenge parameters, such as duration, goals, and participation criteria. ",
  },
  {
    id: "3",
    text: "Access to analytics and reporting functionalities to track engagement, participation rates, and overall wellness trends within the organization. ",
  },


];


export { AppPlatformSectionData }
import React from "react";

import SalesImage from "../../../Assets/SalesAndImpact.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import {CommunicationSectionData,CommunicationSectionImg} from './CommunicationSectionData'

import styles from "./CommunicateSection.module.css";

const CommunicateSection = ({CommunicationData,nestedCommunicationData}) => {
  console.log("datas",CommunicationData,CommunicationSectionImg[CommunicationData]);
  return (
    <div className={styles.CommunicateSectionContainer}>
      {/* <div className={styles.CommunicateSectionContent}>
        <div className={styles.CommunicateSectionHeader}>All-in-one</div>
        <div className={styles.CommunicateSectionText}>
          Zilla Software is for every industry
        </div>
      </div> */}

      <div className={styles.CommunicateSectionImgContainer}>
        <img src={CommunicationSectionImg[CommunicationData]?.[nestedCommunicationData]?.image} alt="" className={styles.CommunicateSection} />
        <div className={styles.CommunicateContentSection}>
          <ul>
            <div className={styles.CommunicateSectionTitle}>{CommunicationSectionImg[CommunicationData]?.[nestedCommunicationData]?.title}</div>
            <div>{CommunicationSectionImg[CommunicationData]?.[nestedCommunicationData]?.desc}</div>
          {CommunicationSectionData[CommunicationData].map((data) => (
            <li key={data.id} className="my-3">
              <div className="d-flex align-items-center">
                <img src={ArrowRight} height="20px" width="20px" className="me-3" />
                <div className={styles.CommunicateContentSectionPoints}>
                {data.text}
                </div>{" "}
              </div>
            </li>
          ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { CommunicateSection };

import React from "react";

import SalesImage from "../../../Assets/SalesAndImpact.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import { SalesAndImpactSectionData } from './SalesAndImpactSectionData'

import styles from "./SalesAndImpactSection.module.css";
import { Container, Row, Col } from "react-bootstrap";

const SalesAndImpactSection = () => {
  return (
    <Container>
      <Row>
        <Col md={7}>
          <div className={styles.SalesAndImpactContainer}>
            <div className={styles.SalesAndImpactSectionContent}>
              {/* <div className={styles.SalesAndImpactSectionHeader}>Uses</div> */}

            </div>
            <div className={styles.SalesAndImpactImgContainer}>

              <div className={styles.SalesAndImpactContentSection}>
                <div className={styles.SalesAndImpactSectionText}>
                  Uses
                </div>
                <ul>
                  {SalesAndImpactSectionData.map((data) => (
                    <li key={data.id} className="my-3">
                      <div className="d-flex align-items-center">
                        <img src={ArrowRight} height="20px" width="20px" className="me-3" />
                        <div className={styles.SalesAndImpactContentSectionPoints}>
                          {data.text}
                        </div>{" "}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col md={5}>
          <img src={SalesImage} alt="" className={` ${styles.SalesAndImpactSection} mt-lg-0 pt-lg-2`} />
        </Col>

      </Row>
    </Container>


  );
};

export { SalesAndImpactSection };

import React from "react";

import PlatformImg from "../../../Assets/AppPlatform.png";
import ArrowRight from "../../../Assets/arrow-right.svg";
import playStore from '../../../Assets/googleplay.svg'
import appStore from '../../../Assets/appstore.svg'
import { AppPlatformSectionData } from "./AppPlatformSectionData";
import home2 from "../../../Assets/home2.png";
import styles from "./AppPlatform.module.css";
const AppPlatform = () => {
  return (
    <div className={styles.AppPlatformContainer}>

      <div className={styles.AppPlatformImgContainer}>
        <div className={styles.AppPlatformContentSection}>
          {/* <div className={styles.AppPlatformHeading}>Get zilla app</div> */}
          <div className={styles.AppPlatformContent}>Administrator Dashboard </div>
          <ul>
            {AppPlatformSectionData.map((data) => (
              <li key={data.id} className="my-3">
                <div className="d-flex align-items-center">
                  <img
                    src={ArrowRight}
                    height="20px"
                    width="20px"
                    className="me-3"
                  />
                  <div className={styles.AppPlatformContentSectionPoints}>
                    {data.text}
                  </div>{" "}
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.AppPlatformBtns}>
            <img src={playStore} className={styles.AppPlatformImg} alt="play store" />
            <img src={appStore} className={styles.AppPlatformImg} alt="app store" />
          </div>
        </div>
        <img src={home2} alt="" className={styles.AppPlatformSection} />

      </div>
    </div>
  );
};

export { AppPlatform };

import React from "react";
import { Header } from "../Components/Header";
import { HeroSection } from "../Components/HeroSection/HeroSection";
import { Section2 } from "../Components/Section2";
import { Section3 } from "../Components/Section3/Section3";
import { BenefitSection } from "../Components/Home/BenefitSection";
import { ManageAndMonitorSection } from "../Components/Home/ManageAndMonitorSection";
import { ContactUsSection } from "../Components/Home/ContactUsSection";
import { AppPlatform } from "../Components/Home/AppPlatform";
import { FaqSection } from "../Components/Home/FAQ";
import { Footer } from "../Components/Footer";

const LocationTracking = () => {
  return (
    <>
      <Header />
      <HeroSection HeroSection={5} nestedHeroSection={0} />
      <Section2 section2__data={4} nestedsection2__data={0} />
      <Section3 section3__data={4} nestedsection3__data={0} />
      <BenefitSection section4__data={5} />
      <ManageAndMonitorSection section5__data={5} />
      <ContactUsSection />
      <AppPlatform />
      <FaqSection />
      <Footer />
    </>
  );
};

export { LocationTracking };

import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import { ManageAndMonitorSectiondata } from './ManageAndMonitorSectionData'
import { Section5Data } from "../../../ReusableData/Section5Data";
import CardIcon from "../../../Assets/ManageAndMonitor/ManageAndMonitorIcon.svg";
import styles from "./ManageAndMonitorSection.module.css";

const ManageAndMonitorSection = ({ section5__data }) => {
  return (
    <>
      <div className={styles.ManageAndMonitorContainer}>
        <div className={styles.ManageAndMonitorContent}>
          <div className={styles.ManageAndMonitorHeader}>
            How does Talisma Wellness improve the efficiency of your field force?
          </div>
          <div className={styles.ManageAndMonitorText}>
            Aiding your workforce with capabilities powered by digitization
          </div>
        </div>
        <Container className="mx-auto">
          <Row>
            {Section5Data[section5__data].map(data => (
              <Col xs={12} md={6} lg={4}>
                <div className={styles.ManageAndMonitorBox}>
                  <img src={CardIcon} alt="Icon" />
                  <div className="fw-bold">{data.title}</div>
                  <div>
                    {data.para}
                  </div>
                  <div className={styles.ManageAndMonitorBoxLink}><Link to="">Read More</Link></div>
                </div>
              </Col>
            ))}



          </Row>
        </Container>
      </div>
    </>
  );
};

export { ManageAndMonitorSection };

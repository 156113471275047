import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { industrySectionData } from "../../../ReusableData/IndustrySectiondata";
import IndustriesIcon from "../../../Assets/IndustriesIcon/IndustriesIcon.svg";

import styles from "./CapabilitiesSection.module.css";

const CapabilitiesSection = ({data}) => {
  return (
    <>
      <Container>
        <div className={styles.CapabilitiesSectionContent}>
          <div className={styles.CapabilitiesSectionHeader}>
          How chat will benefit
          </div>
          <div className={styles.CapabilitiesSectionText}>
          Essential capabilities
          </div>
        </div>
        <Row>
          {industrySectionData[data].map((data) => (
            <Col xs={12} md={6} lg={4}>
              <div className={styles.CapabilitiesSectionBox}>
                <div className={styles.CapabilitiesSectionIconSection}>
                  <img src={IndustriesIcon} alt="icon" />
                  <div className="ms-3">{data.title}</div>
                </div>
                <div>{data.para}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export { CapabilitiesSection };

import { Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { Home } from "./Pages/Home";
import { Chat } from "./Pages/Chat";
import { Order } from "./Pages/Order";

import "./App.module.css";
import { ExpenseManagement } from "./Pages/ExpenseManagement";
import { TaskManagement } from "./Pages/TaskManagement";
import { Attendance } from "./Pages/Attendance";
import { LocationTracking } from "./Pages/LocationTracking";
import { EmployeeDashboard } from "./Pages/EmployeeDashboard";
import { DashboardsAndReports } from "./Pages/DashboardsAndReports";
import { ContactUs } from "./Pages/ContactUs";
import { ErrorPage } from "./Pages/ErrorPage";
import ScrollToTop from "./Helpers/ScrollToTop";
import { Pricing } from "./Pages/Pricing";
import { PrivacyPolicyPage } from "./Pages/PrivacyPolicyPage";
import { TermsAndConditions } from "./Components/TermsAndConditions";
import { TermsPage } from "./Pages/TermsPage";

function App() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
          <Route path="/order" element={<Order />}></Route>
          <Route
            path="/expense-management"
            element={<ExpenseManagement />}
          ></Route>
          <Route path="/task-management" element={<TaskManagement />}></Route>
          <Route path="/attendance" element={<Attendance />}></Route>
          <Route
            path="/location-tracking"
            element={<LocationTracking />}
          ></Route>
          <Route
            path="/employee-dashboard"
            element={<EmployeeDashboard />}
          ></Route>
          <Route
            path="/dashboard-and-reports"
            element={<DashboardsAndReports />}
          ></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>
          <Route path="/terms-and-conditions" element={<TermsPage />}></Route>
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;

import React from "react";

import ErrorImage from "../../Assets/Error.svg";
import styles from "./Error404.module.css";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  const returnHome = () => {
    return navigate("/");
  };
  return (
    <>
      <div className={styles.Error404__container}>
        <img src={ErrorImage} alt="Error" className={styles.Error404_image} />
        <div className={styles.Error404_text1}>Page Not Found</div>
        <div className={styles.Error404_text2}>
          It looks like the page you're trying to access might have been removed
          or moved to a new location. We apologize for the inconvenience.
        </div>
        <button className={styles.Error404_button} onClick={returnHome}>
          Go Home
        </button>
      </div>
    </>
  );
};

export { Error404 };

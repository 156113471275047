import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import { Section4Data } from "../../../ReusableData/Section4Data";
import { BenefitSectiondata } from './BenefitSectionData'
import CardIcon from "../../../Assets/ManageAndMonitor/ManageAndMonitorIcon.svg";
import styles from "./BenefitSection.module.css";

const BenefitSection = ({ section4__data }) => {
  return (
    <>
      <div className={styles.BenefitSectionContainer}>
        <div className={styles.BenefitSectionContent}>
          <div className={styles.BenefitSectionText}>
            Why Talisma
          </div>
          <div className={styles.BenefitSectionHeader}>
            Why choose us?
          </div>
        </div>
        <Container className="mx-auto">
          <Row>
            {Section4Data[section4__data].map(data => (
              <Col xs={12} md={6} lg={4}>
                <div className={styles.BenefitSectionBox}>
                  <img src={CardIcon} alt="Icon" />
                  <div className="fw-bold">{data.title}</div>
                  <div className="text-center">
                    {data.para}
                  </div>
                </div>
              </Col>
            ))}



          </Row>
        </Container>
      </div>
    </>
  );
};

export { BenefitSection };

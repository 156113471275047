const industrySectionData = [
  // Home Page Industru section
  [
    {
      icon: "",
      title: "Implementation",
      para: "Helps to easily manage your Sales Executives by tracking their location and travel duration.",
    },
    {
      icon: "",
      title: "Precision",
      para: "Generates concise reports on sales operations, including the status of tasks, time spent on each task, and expenses incurred.",
    },
    {
      icon: "",
      title: "Security",
      para: "All data is encrypted and stored securely on servers, ensuring the confidentiality of your information. ",
    },
    {
      icon: "",
      title: "Enhanced communication",
      para: "Enables communication with employees in real-time, improving collaboration and reducing communication errors. ",
    },
    {
      icon: "",
      title: "Efficient resource allocation",
      para: " Optimized resource allocation by assigning tasks to the most suitable employees based on location, skill set, and availability.",
    },
    {
      icon: "",
      title: "Reliability",
      para: "The meter reading feature ensures that the Sales Executive is within the radius of the client's location. This helps in avoiding fake tasks.",
    },
  ],

  // Chat Page Capabilities Section
  [
    {
      icon: "",
      title: "Improved communication",
      para: "Field staff can communicate in real-time when stuck en route to a location. ",
    },
    {
      icon: "",
      title: "Timely intervention",
      para: "Managers can intervene to help the field staff with the right advice.",
    },
    {
      icon: "",
      title: "Faster error rectification",
      para: "Managers can detect and take corrective measures quickly. ",
    },
  ],
];

export { industrySectionData };

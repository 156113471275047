import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, NavLink } from "react-router-dom";
import headerlogo from "../../Assets/headerlogo.png";
import "./Header.css";
import { Button, Modal } from "react-bootstrap";
import { ContactUsForm } from "../ContactusForm/ContactUsForm";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header className="header">
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={headerlogo} alt="Logo" style={{ height: "40px" }}></img>
          </Link>
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="sr-only">Toggle navigation</span>
          <GiHamburgerMenu />
        </button>
        <ul className={`menu ${isOpen ? "open" : "close"}`}>
          {/* <div style={{color:"black"}} onClick={() => setIsOpen(false)}>X</div> */}
          <li onClick={toggleMenu}>
            <NavLink to="/">HOME</NavLink>
          </li>
          <li onClick={toggleMenu}>
            <a href="#about" onClick={toggleMenu}>
              ABOUT
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#scope">SCOPE</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#challenges">CHALLENGES</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#faq">FAQ</a>
          </li>

          <li>
            <button
              className="headerButton"
              onClick={() => {
                handleShow();
                toggleMenu();
              }}
            >
              Request Demo
            </button>
          </li>
          <li>
            <a href="#callback" onClick={toggleMenu}>
              <button className="headerButton">Request Callback</button>
            </a>
          </li>
        </ul>
      </nav>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactUsForm type={"model"} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </header>
  );
}

export { Header };

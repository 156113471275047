import React from "react";

import { FooterData, FootersData } from "./FooterData";
import styles from "./Footer.module.css";
import AppStore from "../../Assets/appstore.svg";
import PlayStore from "../../Assets/googleplay.svg";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.FooterContainer}>
      <Container className="">
        <Row className="d-flex justify-content-center " style={{ marginLeft: '0px' }}>
          {FootersData.map((data) => (
            <Col xs={12} md={6} lg={4} style={{ marginLeft: "0px" }}>
              <div className={styles.FooterManagement}>
                <div className={styles.FooterHeading}> {data.title}</div>
                {data.links.map((value) => (
                  <div className={styles.FooterContent}>
                    <img src={value.icon} className={value.icon && "me-2"} />
                    <a href={value.link} target="_blank">
                      <div className={`py-1 ${styles.FooterLinksColor}`}>
                        {value.text}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </Col>
          ))}

          {/* <div className={styles.FooterBottomLinks}>
            <div className={styles.FooterBottomLinksSection}>
              <span className={styles.FooterBottomLinksName}>App Links</span>
              <img src={PlayStore} className="me-3" alt="play store" />
              <img src={AppStore} alt="app store" />
            </div>
          </div> */}
          <div className={` ${styles.FooterBottomLinks1}mt-0 mb-2 `}>
            <div className="">
              <span className={` ${styles.FooterBottomLinks2Name} `}>
                Copyright ©2024, Talisma Wellness Pvt Ltd
              </span>
            </div>
            {/* <div className={styles.FooterBottomPolicyTerms}>
              <Link to="https://talismawellness.com/privacy-policy/index.html"><div className="me-3" >Privacy policy</div></Link>
              <Link to="https://talismawellness.com/account-deletion/index.html"><div >Account deletion</div></Link>
            </div> */}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export { Footer };
